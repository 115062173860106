import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";

export default function AddSalesForm({ formik }) {
  const { allCustomers } = useSelector((state) => state.CustomersReducer);

  const { saleId } = useParams();

  const statusOptions = [
    { name: "Active", value: true },
    { name: "Deactive", value: false },
  ];

  // console.log(formik.values);

  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {saleId ? "Edit Customer" : "Add Customer"}
        </span>
      </Divider>

      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="">
              Customer Name
            </label>
            <span className=" w-full">
              <InputText
                id="name"
                name="name"
                className="w-full text-lg p-primary-input"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.name && formik.errors?.name && (
            <div className="p-error">{formik.errors?.name}</div>
          )}
        </div>

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="lname" className="">
              Customer Last Name
            </label>
            <span className=" w-full">
              <InputText
                id="lname"
                name="lname"
                className="w-full text-lg p-primary-input"
                value={formik.values.lname}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.lname && formik.errors?.lname && (
            <div className="p-error">{formik.errors?.lname}</div>
          )}
        </div> */}

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="customer" className="">
              Select Customer
            </label>
            <Dropdown
              id="customer"
              name="customer"
              className="!w-full text-lg p-primary-input"
              value={formik.values.customer}
              onChange={formik.handleChange}
              options={allCustomers}
              optionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-2" },
                filterInput: { className: "pl-8" },
              }}
            />
          </div>
          {formik.touched?.customer && formik.errors?.customer && (
            <div className="p-error">{formik.errors?.customer}</div>
          )}
        </div> */}

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="subCategoryId" className="">
              Select Sub Category
            </label>
            <Dropdown
              id="subCategoryId"
              name="subCategoryId"
              className="!w-full text-lg p-primary-input"
              value={formik.values.subCategoryId}
              onChange={formik.handleChange}
              options={filterSubCategories}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-1" },
                filterInput: { className: "pl-6" },
              }}
            />
          </div>
          {formik.touched?.subCategoryId && formik.errors?.subCategoryId && (
            <div className="p-error">{formik.errors?.subCategoryId}</div>
          )}
        </div> */}

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="price" className="">
              Price
            </label>
            <span className=" w-full">
              <InputNumber
                id="price"
                name="price"
                className="w-full text-lg p-primary-input"
                value={formik.values.price}
                onValueChange={formik.handleChange}
                prefix="RS "
                disabled={!formik.values.samePrice}
              />
            </span>
          </div>
          {formik.touched?.price && formik.errors?.price && (
            <div className="p-error">{formik.errors?.price}</div>
          )}
          <div className="mt-2">
            <Checkbox
              inputId="samePrice"
              name="samePrice"
              id="samePrice"
              onChange={formik.handleChange}
              checked={formik.values.samePrice}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="samePrice" className="ml-2">
              Same Price for all varients
            </label>
          </div>
        </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="phone" className="">
              Customer Phone Number
            </label>
            <span className="w-full">
              <InputText
                id="phone"
                name="phone"
                className="w-full text-lg p-primary-input"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
            </span>
          </div>
        </div>

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="status" className="">
              Status
            </label>
            <SelectButton
              name="status"
              id="status"
              options={statusOptions}
              optionLabel="name"
              optionValue="value"
              className="flex"
              pt={{
                root: { className: "flex" },
                button: ({ context }) => ({
                  className: context.selected
                    ? "p-primary-highlight-btn w-full text-lg text-center"
                    : "w-full text-lg text-center",
                }),
              }}
              value={formik.values.status}
              onChange={formik.handleChange}
            />
            {formik.touched.status && formik.errors.status && (
              <div className="p-error">{formik.errors.status}</div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}
