import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./scss/index.scss";
import Store from "./store";
import { Provider } from "react-redux";
import Home from "./components/Home";
import Login from "./components/Auth/Login";
import AuthRoutes from "./protectedRoutes/AuthRoutes";
import PrivateRoutes from "./protectedRoutes/PrivateRoutes";
import RootLayout from "./layouts/RootLayout";
import DashboardMain from "./pages/Dashboard/DashboardMain";
import CategoriesMain from "./pages/Categories/CategoriesMain";
import AddCategories from "./pages/Categories/AddCategories";
import EditCategories from "./pages/Categories/EditCategories";

import ProductMain from "./pages/Products/ProductMain";
import AddProduct from "./pages/Products/AddProduct";
import ManageUserMain from "./pages/ManageUser/ManageUserMain";
import AddUser from "./pages/ManageUser/AddUser";
import SetNewPassword from "./components/Auth/SetNewPassword";
import SalesMain from "./pages/Sales/SalesMain";
import AddSales from "./pages/Sales/AddSales";
import SalesRoutes from "./protectedRoutes/SalesRoutes";
import SaleInvoiceMain from "./pages/Invoice/SaleInvoice/SaleInvoiceMain";

function App() {
  return (
    <Provider store={Store}>
      <Router>
        <Routes>
          <Route
            element={
              <RootLayout>
                <PrivateRoutes />
              </RootLayout>
            }
          >
            <Route path="/dashboard" exact element={<DashboardMain />} />
            <Route path="/categories" exact element={<CategoriesMain />} />
            <Route path="/add-categories" exact element={<AddCategories />} />

            <Route
              path="/edit-categories/:id"
              exact
              element={<EditCategories />}
            />

            <Route path="/products" exact element={<ProductMain />} />
            <Route path="/add-product" exact element={<AddProduct />} />
            <Route
              path="/edit-product/:productId"
              exact
              element={<AddProduct />}
            />
            <Route path="/manage-users" exact element={<ManageUserMain />} />
            <Route path="/add-user" exact element={<AddUser />} />
            <Route path="/edit-user/:userId" exact element={<AddUser />} />
          </Route>

          <Route
            element={
              <RootLayout>
                <SalesRoutes />
              </RootLayout>
            }
          >
            <Route path="/dashboard-sales" exact element={<DashboardMain />} />
            <Route path="/sales" exact element={<SalesMain />} />
            <Route path="/add-sales" exact element={<AddSales />} />
            <Route path="/edit-sales/:saleId" exact element={<AddSales />} />
            <Route
              path="/invoice-sales/:saleId"
              exact
              element={<SaleInvoiceMain />}
            />
          </Route>

          <Route path="/" element={<AuthRoutes />}>
            <Route index element={<Login />} />
          </Route>

          <Route
            path="/users/:id/set-new-password/:token"
            element={<SetNewPassword />}
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
