import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSelector } from "react-redux";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Menu } from "primereact/menu";
import { useReactToPrint } from "react-to-print";
import { Button } from "primereact/button";

export default function AddProductTable({
  varients,
  removeVarient,
  editVarient,
  category_name,
}) {
  const menu = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const { varientOptions } = useSelector((state) => state.ProductReducer);
  const [showDelDialog, setShowDelDialog] = useState(false);
  let variantId;
  const accept = () => {
    removeVarient(selectedItem?.id);
  };

  const reject = () => {
    setShowDelDialog(false);
  };

  const handlePrint = useReactToPrint({
    pageStyle:
      "@page { size: 2in 1in;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }",

    content: () => document.getElementById(variantId),
  });

  let items = [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        editVarient(selectedItem);
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        setShowDelDialog(true);
      },
    },
  ];

  const actionBtn = (rowData) => (
    <React.Fragment>
      <div className=" ">
        <i
          className="pi pi-cog text-secondary cursor-pointer"
          onClick={(e) => {
            menu.current.toggle(e);
            setSelectedItem(rowData);
          }}
        ></i>
        <Menu model={items} popup ref={menu} className="p-0 z-50" />
      </div>
    </React.Fragment>
  );

  const printBarcodeBody = (rowData) => {
    //setVariantId("receipt-component-variant-" + rowData?.id);

    return (
      <React.Fragment>
        <>
          <div className=" hidden ">
            {rowData?.barcode_img ? (
              <div
                className="text-[8px] overflow-hidden"
                id={"receipt-component-variant-" + rowData?.id}
                style={{
                  width: "2in",
                  height: "1in",
                }}
              >
                <div
                  className="flex leading-none flex-col"
                  style={{ width: "2in", height: "0.26in" }}
                >
                  <div className="flex justify-center">{category_name}</div>
                  <div className="flex  justify-center ">
                    <span>Rs {rowData?.price.toLocaleString("en-IN")}/-</span>
                  </div>
                  <div className="flex  justify-center ">
                    <span>Tehzeeb Designer</span>
                  </div>
                </div>
                <div className="flex justify-center" style={{}}>
                  <img
                    src={`${rowData?.barcode_url}${rowData?.barcode_img}`}
                    className=""
                    style={{ width: "2in", height: "0.74in" }}
                  />
                </div>
              </div>
            ) : (
              <div
                className="flex mt-14 ml-4"
                id={"receipt-component-variant-" + rowData?.id}
              >
                <p>
                  *The barcode will be generated after you submit product
                  information.
                </p>
              </div>
            )}
          </div>

          <Button
            label="Print"
            icon="pi pi-print"
            className="p-black-btn"
            size="small"
            type="button"
            onClick={() => {
              variantId = `receipt-component-variant-${rowData?.id}`;

              return handlePrint();
            }}
          />
        </>
      </React.Fragment>
    );
  };
  const priceBody = (rowData) => {
    return `RS ${rowData.price.toLocaleString("en-IN")}`;
  };

  const purchasePriceBody = (rowData) => {
    return `RS ${rowData.purchasePrice.toLocaleString("en-IN")}`;
  };

  return (
    <div>
      <div className="card shadow-sm">
        <DataTable
          value={varients}
          tableStyle={{ minWidth: "50rem" }}
          stripedRows
          size="small"
          pt={{
            filterOperator: { className: "w-3" },
            footerRow: { className: "!bg-red-900" },
          }}
        >
          <Column field="id" header="ID #" />
          {varientOptions &&
            varientOptions.map((option) => (
              <Column
                key={option.id}
                field={(row) => row[option?.label]?.value}
                header={option.label}
                headerClassName="capitalize"
              />
            ))}
          <Column field="quantity" header="Quantity" />

          <Column
            field="price"
            header="Purchase Price"
            body={purchasePriceBody}
          />
          <Column field="price" header="Sale Price" body={priceBody} />
          <Column header="Action" body={actionBtn} />
          <Column header="Barcode" body={printBarcodeBody} />
        </DataTable>
      </div>

      <ConfirmDialog
        message="Do you want to delete this record?"
        header="Delete confirmation"
        icon="pi pi-info-circle"
        accept={accept}
        reject={reject}
        acceptLabel="Yes"
        visible={showDelDialog}
        onHide={() => setShowDelDialog(false)}
        pt={{
          acceptButton: {
            className: "p-red-btn",
          },
          rejectButton: {
            className: "p-primary-btn",
          },
          root: {
            className: "w-9/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-3/12",
          },
          icon: {
            className: "mx-2",
          },
        }}
      />
    </div>
  );
}
