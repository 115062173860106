import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
export default function AddProductForm({ formik, specificProduct }) {
  const { categories } = useSelector((state) => state.CategoryReducer);

  const { productId } = useParams();
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("receipt-component"),
  });
  const statusOptions = [
    { name: "Active", value: true },
    { name: "Deactive", value: false },
  ];
  useEffect(() => {
    console.log(productId);
  }, [productId]);
  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {productId ? "Edit Product" : "Add Product"}
        </span>
      </Divider>
      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="title" className="">
              Product Title
            </label>
            <span className=" w-full">
              <InputText
                id="title"
                name="title"
                className="w-full text-lg p-primary-input"
                value={formik.values.title}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.title && formik.errors?.title && (
            <div className="p-error">{formik.errors?.title}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="articleName" className="">
              Article Number
            </label>
            <span className=" w-full">
              <InputText
                id="articleName"
                name="articleName"
                className="w-full text-lg p-primary-input"
                value={formik.values.articleName}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.articleName && formik.errors?.articleName && (
            <div className="p-error">{formik.errors?.articleName}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryId" className="">
              Select Category
            </label>
            <Dropdown
              id="categoryId"
              name="categoryId"
              className="!w-full text-lg p-primary-input"
              value={formik.values.categoryId}
              onChange={formik.handleChange}
              options={categories}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-1" },
                filterInput: { className: "pl-6" },
              }}
            />
          </div>
          {formik.touched?.categoryId && formik.errors?.categoryId && (
            <div className="p-error">{formik.errors?.categoryId}</div>
          )}
        </div>

        {/* <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="subCategoryId" className="">
              Select Sub Category
            </label>
            <Dropdown
              id="subCategoryId"
              name="subCategoryId"
              className="!w-full text-lg p-primary-input"
              value={formik.values.subCategoryId}
              onChange={formik.handleChange}
              options={filterSubCategories}
              optionLabel="name"
              optionValue="id"
              filter
              pt={{
                root: { className: "w-full" },
                input: { className: "w-full p-primary-input" },
                filterIcon: { className: "ml-1" },
                filterInput: { className: "pl-6" },
              }}
            />
          </div>
          {formik.touched?.subCategoryId && formik.errors?.subCategoryId && (
            <div className="p-error">{formik.errors?.subCategoryId}</div>
          )}
        </div> */}

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="purchasePrice" className="">
              Purchase Price
            </label>
            <span className=" w-full">
              <InputNumber
                id="purchasePrice"
                name="purchasePrice"
                className="w-full text-lg p-primary-input"
                value={formik.values.purchasePrice}
                onValueChange={formik.handleChange}
                prefix="RS "
                disabled={!formik.values.samePurchasePrice}
              />
            </span>
          </div>
          {formik.touched?.purchasePrice && formik.errors?.purchasePrice && (
            <div className="p-error">{formik.errors?.purchasePrice}</div>
          )}
          <div className="mt-2">
            <Checkbox
              inputId="samePurchasePrice"
              name="samePurchasePrice"
              id="samePurchasePrice"
              onChange={formik.handleChange}
              checked={formik.values.samePurchasePrice}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="samePurchasePrice" className="ml-2">
              Same Price for all varients
            </label>
          </div>
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="price" className="">
              Sale Price
            </label>
            <span className=" w-full">
              <InputNumber
                id="price"
                name="price"
                className="w-full text-lg p-primary-input"
                value={formik.values.price}
                onValueChange={formik.handleChange}
                prefix="RS "
                disabled={!formik.values.samePrice}
              />
            </span>
          </div>
          {formik.touched?.price && formik.errors?.price && (
            <div className="p-error">{formik.errors?.price}</div>
          )}
          <div className="mt-2">
            <Checkbox
              inputId="samePrice"
              name="samePrice"
              id="samePrice"
              onChange={formik.handleChange}
              checked={formik.values.samePrice}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="samePrice" className="ml-2">
              Same Price for all varients
            </label>
          </div>
        </div>

        <div className="col-span-1 ">
          <div className="flex flex-col gap-2">
            <label htmlFor="description" className="">
              Description
            </label>
            <span className=" w-full">
              <InputText
                id="description"
                name="description"
                className="w-full text-lg p-primary-input"
                value={formik.values?.description}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.description && formik.errors?.description && (
            <div className="p-error">{formik.errors?.description}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="status" className="">
              Status
            </label>
            <SelectButton
              name="status"
              id="status"
              options={statusOptions}
              optionLabel="name"
              optionValue="value"
              className="flex"
              pt={{
                root: { className: "flex" },
                button: ({ context }) => ({
                  className: context.selected
                    ? "p-primary-highlight-btn w-full text-lg text-center"
                    : "w-full text-lg text-center",
                }),
              }}
              value={formik.values.status}
              onChange={formik.handleChange}
            />
            {formik.touched.status && formik.errors.status && (
              <div className="p-error">{formik.errors.status}</div>
            )}
          </div>
        </div>
      </div>
      {productId &&
      specificProduct?.barcode_img &&
      specificProduct?.barcode_url ? (
        <div className="my-6 px-4 flex gap-8 justify-end">
          <div className=" scale-150">
            <div
              className="text-[8px] overflow-hidden"
              id="receipt-component"
              style={{
                width: "2in",
                height: "1in",
              }}
            >
              <div className="flex font-bold mb-1 flex-col">
                <div className="flex justify-center ">
                  {specificProduct?.category_name}
                </div>
                <div className="flex gap-3 justify-center ">
                  <span>
                    Rs {formik.values.price.toLocaleString("en-IN")}/-
                  </span>
                </div>
                <div className="flex gap-3 justify-center ">
                  <span>ShoeMaker </span>
                </div>
              </div>
              <div className="flex justify-center px-3" style={{}}>
                <img
                  src={`${specificProduct?.barcode_url}${specificProduct?.barcode_img}`}
                  className=""
                  style={{ width: "20mm" }}
                />
              </div>
            </div>
          </div>
          <div className="my-auto">
            <Button
              label="Print Barcode"
              icon="pi pi-print"
              className="p-black-btn"
              onClick={handlePrint}
              type="button"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
