import React, { useEffect } from "react";
import Invoice from "./Invoice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getspecificSale } from "../../../store/AsyncMethods/SalesMethod";
import {
  getAllVarients,
  getProducts,
} from "../../../store/AsyncMethods/ProductMethod";
import { RESET_SPECIFIC_SALES } from "../../../store/Types/SalesTypes";
import { useReactToPrint } from "react-to-print";

export default function SaleInvoiceMain() {
  const dispatch = useDispatch();
  const { saleId } = useParams();
  useEffect(() => {
    if (saleId) {
      dispatch(getspecificSale(saleId));
    }
  }, []);
  useEffect(() => {
    dispatch(getProducts());
    dispatch(getAllVarients());
  }, []);

  const navigate = useNavigate();
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("receipt-component"),
    // onBeforePrint: () => {
    //   window.close();
    // },
    onAfterPrint: () => {
      dispatch({ type: RESET_SPECIFIC_SALES });
      navigate("/sales");
    },
    // onBeforePrint: () => navigate(-1),
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      handlePrint();
    }, 1000); // 10 seconds delay in milliseconds

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);
  return (
    <div>
      <div className="bg-white w-3/12 mx-auto h-screen">
        {/* <div className="text-center mb-3">
          <button onClick={handlePrint}>Print Receipt</button>
        </div> */}
        <div id="receipt-component">
          <Invoice />
        </div>
      </div>
    </div>
  );
}
