import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCategory } from "../../store/AsyncMethods/CategoryMethod";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { RESET_ERROR } from "../../store/Types/AuthTypes";
import { toast } from "react-toastify";

import {
  addProduct,
  getOptionValues,
  getProducts,
  getVarientOption,
  getspecificProduct,
  updateProduct,
} from "../../store/AsyncMethods/ProductMethod";
import AddProductTable from "./AddProductTable";
import AddVarientForm from "./AddVarientForm";
import AddProductForm from "./AddProductForm";
import { RESET_SPECIFIC_PRODUCT } from "../../store/Types/ProductTypes";

export default function AddProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const location = useLocation();

  const [showVarientForm, setShowVarientForm] = useState(false);

  const { error, user } = useSelector((state) => state.AuthReducer);
  const { varientOptions, specificProduct } = useSelector(
    (state) => state.ProductReducer
  );

  // const { subCategories } = useSelector((state) => state.CategoryReducer);

  // const [filterSubCategories, setFilterSubCategories] = useState([]);

  const [validateVarient, setValidateVarient] = useState(false);

  const varientOptionsValidation = varientOptions.reduce((acc, option) => {
    if (option.label !== "style") {
      acc[option.label] = Yup.object().required(`${option.label} is required`);
    }
    return acc;
  }, {});

  const [editingVarient, setEditingVarient] = useState(null);

  const editVarient = (varient) => {
    setEditingVarient(varient);
    setShowVarientForm(true);
  };

  useEffect(() => {
    if (specificProduct) {
      console.log(specificProduct);

      formik.setValues({
        title: specificProduct.title || "",
        articleName: specificProduct.article_name || "",
        description: specificProduct.description || "",
        status: specificProduct.is_active === 1 ? true : false,
        purchasePrice: specificProduct.purchase_price || 0,
        price: specificProduct.price || 0,
        //subCategoryId: specificProduct.sub_category_id || "",
        categoryId: specificProduct.category_id || "",
        samePrice: specificProduct.is_same_price === 1 ? true : false,
        samePurchasePrice:
          specificProduct.is_same_purchase_price === 1 ? true : false,
        userId: user.id,
        varients: specificProduct.varients || [],
        varient: {
          quantity: "",
          purchasePrice: 0,
          price: 0,
        },
      });
    }
  }, [specificProduct]);

  const formik = useFormik({
    initialValues: {
      title: specificProduct ? specificProduct.title : "",
      articleName: specificProduct ? specificProduct.article_name : "",
      description: specificProduct ? specificProduct.description : "",
      status: specificProduct
        ? specificProduct.is_active === 1
          ? true
          : false
        : true,
      purchasePrice: specificProduct ? specificProduct.purchase_price : 0,
      price: specificProduct ? specificProduct.price : 0,
      //subCategoryId: specificProduct ? specificProduct.sub_category_id : "",
      categoryId: specificProduct ? specificProduct.category_id : "",
      samePrice: specificProduct
        ? specificProduct.is_same_price === 1
          ? true
          : false
        : true,
      samePurchasePrice: specificProduct
        ? specificProduct.is_same_purchase_price === 1
          ? true
          : false
        : true,
      userId: user.id,
      varients: specificProduct ? specificProduct.varients : [],
      varient: {
        quantity: "",
        purchasePrice: 0,
        price: 0,
      },
    },
    validationSchema: Yup.object({
      categoryId: Yup.number().required("Category Required"),
      status: Yup.boolean().required("Status Required"),
      price: Yup.number().required("Price Required"),
      title: Yup.string().required("Product Title Required"),
      articleName: Yup.string().required("Article Number Required"),
      // description: Yup.string().required("Description Required"),
      samePrice: Yup.boolean(),
      varient: validateVarient
        ? Yup.object().shape({
            ...varientOptionsValidation,
            quantity: Yup.number().required("Quantity is required"),
            purchasePrice: Yup.number().required("Purchase Price is required"),
            price: Yup.number().required("Price is required"),
          })
        : Yup.object(),
    }),

    onSubmit: async (data) => {
      if (specificProduct) {
        dispatch(updateProduct(data, productId)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch({ type: RESET_SPECIFIC_PRODUCT });
            dispatch(getProducts());
            navigate("/products");
          }
        });
      } else {
        dispatch(addProduct(data)).then((success) => {
          if (success) {
            formik.resetForm();
            dispatch(getProducts());
            navigate("/products");
          }
        });
      }
    },
  });

  const removeVarient = (varientId) => {
    formik.setValues((prevValues) => {
      const updatedVarients = prevValues.varients.filter(
        (varient) => varient.id !== varientId
      );
      return { ...prevValues, varients: updatedVarients };
    });
  };

  useEffect(() => {
    if (formik?.values?.samePrice) {
      formik.setValues({
        ...formik.values,
        varient: {
          ...formik.values.varient,
          price: formik?.values?.price,
        },
      });
    } else {
      formik.setValues({
        ...formik.values,
        price: 0,
      });
    }
  }, [formik?.values?.samePrice, formik?.values?.price]);

  useEffect(() => {
    if (formik?.values?.samePurchasePrice) {
      formik.setValues({
        ...formik.values,
        varient: {
          ...formik.values.varient,
          purchasePrice: formik?.values?.purchasePrice,
        },
      });
    } else {
      formik.setValues({
        ...formik.values,
        purchasePrice: 0,
      });
    }
  }, [formik?.values?.samePurchasePrice, formik?.values?.purchasePrice]);

  useEffect(() => {
    if (varientOptions.length > 0) {
      varientOptions.map((option) => {
        formik.initialValues.varient = {
          ...formik.initialValues.varient,
          [option.label]: "",
        };
      });
    }
  }, [varientOptions]);

  // useEffect(() => {
  //   if (formik.values.categoryId) {
  //     const filter = subCategories.filter(
  //       (item) => item.category_id === formik.values.categoryId
  //     );

  //     if (filter) {
  //       setFilterSubCategories(filter);
  //     }
  //   }
  // }, [formik.values.categoryId]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: RESET_ERROR });
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      dispatch(getCategory());
      dispatch(getVarientOption());
      dispatch(getOptionValues());
    }
  }, []);

  useEffect(() => {
    if (productId) {
      dispatch(getspecificProduct(productId));
    } else {
      formik.resetForm();
      dispatch({ type: RESET_SPECIFIC_PRODUCT });
    }
  }, []);

  useEffect(() => {
    dispatch({ type: RESET_SPECIFIC_PRODUCT });
    formik.resetForm();
  }, [location.pathname]);

  return (
    <div className="mx-4">
      {/* <div>
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-black-btn"
          onClick={() => {
            formik.resetForm();
            dispatch({ type: RESET_SPECIFIC_PRODUCT });
            navigate(-1);
          }}
        />
      </div> */}
      <form onSubmit={formik.handleSubmit}>
        <AddProductForm
          formik={formik}
          specificProduct={specificProduct}
          //filterSubCategories={filterSubCategories}
        />

        <div>
          <AddVarientForm
            formik={formik}
            showVarientForm={showVarientForm}
            setShowVarientForm={setShowVarientForm}
            validateVarient={validateVarient}
            setValidateVarient={setValidateVarient}
            editingVarient={editingVarient}
            setEditingVarient={setEditingVarient}
          />
        </div>

        <div className="my-3">
          <AddProductTable
            varients={formik.values.varients}
            removeVarient={removeVarient}
            editVarient={editVarient}
          />
        </div>

        <div className="mt-16">
          <div className="flex justify-end gap-4">
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              disabled={showVarientForm}
              onClick={() => {
                dispatch({ type: RESET_SPECIFIC_PRODUCT });
                formik.resetForm();
              }}
            />
            <Button
              label={"Submit"}
              icon="pi pi-check"
              className="p-secondary-btn"
              disabled={showVarientForm}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
