import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useScanDetection from "use-scan-detection-react18";

export default function AddSalesProductForm({
  formik,
  // setShowProductForm,
  setValidateVarient,
  validateVarient,
  // showProductForm,
  editingProduct,
  setEditingProduct,
  currentProductQuantity,
}) {
  const { specificVarients, products } = useSelector(
    (state) => state.ProductReducer
  );
  const [scanProductBarcode, setScanProductBarcode] = useState("");
  const [currentProductTitle, setCurrentProductTitle] = useState("");
  const { user } = useSelector((state) => state.AuthReducer);

  const [varientDropdownOptions, setVarientDropdownOptions] = useState([]);

  const handleScanProductWithBarcode = () => {
    if (scanProductBarcode.length > 0) {
      let matchingVarient = "";
      const matchingProduct = products.find(
        (product) =>
          product.article_name === scanProductBarcode ||
          product.varients.some((varient) => {
            if (varient.barcode === scanProductBarcode) {
              matchingVarient = varient;
              return true;
            }
          })
      );
      if (matchingProduct) {
        formik.setFieldValue("product.product", matchingProduct.id);
        setCurrentProductTitle(matchingProduct.title || "");
        if (matchingVarient) {
          formik.setFieldValue(
            "product.varient",
            matchingVarient?.varient_id || ""
          );
        }
      } else {
        toast.error("No matching product found for this barcode.");
      }
      setScanProductBarcode("");
    }
  };
  useScanDetection({
    onComplete: (code) => {
      setScanProductBarcode(code);
      handleScanProductWithBarcode();
    },
    minLength: 13, // EAN13
  });

  useEffect(() => {
    // Create options for the PrimeReact Dropdown
    const options = specificVarients.map((variant) => {
      const parsedOptions = JSON.parse(variant.options);

      const optionLabel = parsedOptions
        .map((option) => {
          return `${option} - ${variant[option]?.value || "N/A"}`;
        })
        .join("  |  ");

      return { label: optionLabel, value: variant.varient_id };
    });

    setVarientDropdownOptions(options);
  }, [specificVarients]);

  useEffect(() => {
    if (formik.values.product?.product) {
      const product = products.find(
        (pro) => pro.id === formik.values.product?.product
      );

      if (product) {
        setCurrentProductTitle(product?.title || "");
      } else {
        setCurrentProductTitle("");
      }
    } else {
      setCurrentProductTitle("");
    }
  }, [formik.values.product?.product]);

  //   const optionValueFilter = (id) => {
  //     return optionValues.filter((option) => option.option_id === id);
  //   };

  // const getVarientOptions = varientOptions.reduce((acc, option) => {
  //   acc[option.label] = "";
  //   return acc;
  // }, {});

  // const getEditVarientOptions = editingProduct
  //   ? varientOptions.reduce((acc, option) => {
  //       acc[option.label] = editingProduct[option.label];
  //       return acc;
  //     }, {})
  //   : null;

  const handleCancel = () => {
    // setShowProductForm(false);
    setValidateVarient(false);
    setEditingProduct(null);
    formik.setValues({
      ...formik.values,
      product: {
        purchasePrice: 0,
        salePrice: 0,
        product: "",
        varient: "",
        discount: 0,
        quantity: 1,
        total: 0,
      },
    });
  };

  const updateProductInArray = (updatedProduct) => {
    const index = formik.values.products.findIndex(
      (product) => product.id === editingProduct.id
    );

    if (index !== -1) {
      // Update the values of the object at the found index
      formik.values.products[index] = {
        ...formik.values.products[index],
        ...updatedProduct,
      };
    }

    formik.setValues({
      ...formik.values,
      product: {
        purchasePrice: 0,
        salePrice: 0,
        product: "",
        varient: "",
        discount: 0,
        quantity: 1,
        total: 0,
      }, // Reset the varient form
    });

    setEditingProduct(null); // Reset the editingProduct state after updating
    // setShowProductForm(false); // Close the varient form after updating

    toast.success("Varient Updated Successfully!");
  };

  const updateProduct = () => {
    if (editingProduct) {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          if (formik.isValid) {
            updateProductInArray(formik.values.product);
          }
        } else {
          console.log("Validation errors:", errors);
        }
      });
    }
  };

  const handleAddVarient = () => {
    setValidateVarient(true);
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (formik.isValid) {
          const productsArray = Array.isArray(formik.values.products)
            ? formik.values.products
            : [];

          const newProduct = formik.values.product
            ? {
                ...formik.values.product,
                id: formik.values.products.length + 1,
              }
            : {};

          formik.setValues({
            ...formik.values,
            products: [...productsArray, newProduct],
            product: {
              purchasePrice: 0,
              salePrice: 0,
              product: "",
              varient: "",
              discount: 0,
              quantity: 1,
              total: 0,
            }, // Reset the varient form
          });

          // setShowProductForm(false);
          setValidateVarient(false);
          toast.success("Varient Added Successfully!");
        }
      } else {
        formik.handleSubmit();
        console.log("Validation errors:", errors);
      }
    });
  };

  useEffect(() => {
    // Set the formik values based on the editingProduct when it changes
    if (editingProduct) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        product: {
          ...prevValues.product,
          purchasePrice: editingProduct?.purchasePrice,
          salePrice: editingProduct?.salePrice,
          product: editingProduct?.product,
          varient: editingProduct?.varient,
          discount: editingProduct?.discount,
          quantity: editingProduct?.quantity,
          total: editingProduct?.total,
        },
      }));
    }
  }, [editingProduct]);

  useEffect(() => {
    if (
      formik.values?.product?.purchasePrice === 0 &&
      formik.values?.product?.salePrice === 0 &&
      formik.values?.product?.product === "" &&
      formik.values?.product?.varient === "" &&
      formik.values?.product?.discount === 0 &&
      formik.values?.product?.quantity === 1 &&
      formik.values?.product?.total === 0 &&
      formik.values?.products?.length !== 0
    ) {
      setValidateVarient(false);
    } else {
      setValidateVarient(true);
    }
  }, [formik.values?.product]);

  return (
    <>
      {/* <div className="mt-3 flex justify-end">
        <Button
          label="Add Product"
          icon="pi pi-plus"
          className="p-primary-btn"
          onClick={() => {
            setShowProductForm(true);
            setValidateVarient(true);
          }}
        />
      </div> */}

      <div className="card shadow-md rounded-lg p-4 mt-4">
        <Divider>
          <span className="text-2xl font-bold text-center text-primary mx-1">
            {editingProduct ? "Edit" : "Add"} Product
          </span>
        </Divider>
        <div className="px-4 pb-12  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div className="w-full flex">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText
                placeholder="Scan using barcode"
                className="rounded-r-none pl-12 w-full"
                value={scanProductBarcode}
                onChange={(e) => setScanProductBarcode(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleScanProductWithBarcode();
                  }
                }}
              />
            </span>
            <Button
              icon="pi pi-arrow-right"
              className="rounded-l-none p-black-btn"
              type="button"
              onClick={handleScanProductWithBarcode}
            />
          </div>
        </div>
        <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.product" className="">
                Article Number
              </label>
              <Dropdown
                id="product.product"
                name="product.product"
                className="!w-full text-lg p-primary-input"
                value={formik.values?.product?.product}
                onChange={formik.handleChange}
                options={products}
                optionLabel={(option) => `${option.article_name}`}
                optionValue="id"
                filter
                placeholder="Select Article Number"
                showClear
                pt={{
                  root: { className: "w-full" },
                  input: { className: "w-full p-primary-input" },
                  filterIcon: { className: "ml-2" },
                  filterInput: { className: "pl-8" },
                }}
              />
            </div>
            {formik.touched?.product?.product &&
              formik.errors?.product?.product && (
                <div className="p-error">{formik.errors?.product?.product}</div>
              )}
          </div>

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="name" className="">
                Product Title
              </label>
              <span className=" w-full">
                <InputText
                  id="name"
                  name="name"
                  className="w-full text-lg p-primary-input"
                  value={currentProductTitle}
                  disabled
                  // onChange={formik.handleChange}
                />
              </span>
            </div>
            {/* {formik.touched?.name && formik.errors?.name && (
            <div className="p-error">{formik.errors?.name}</div>
          )} */}
          </div>

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.varient" className="">
                Varient
              </label>
              <Dropdown
                id="product.varient"
                name="product.varient"
                className="!w-full text-lg p-primary-input"
                value={formik.values?.product?.varient}
                onChange={formik.handleChange}
                options={varientDropdownOptions}
                optionLabel="label"
                optionValue="value"
                placeholder="Select Varient"
                showClear
                filter
                pt={{
                  root: { className: "w-full" },
                  input: { className: "w-full p-primary-input" },
                  filterIcon: { className: "ml-2" },
                  filterInput: { className: "pl-8" },
                  item: { className: "capitalize" },
                }}
              />
            </div>
            {formik.touched?.product?.varient &&
              formik.errors?.product?.varient && (
                <div className="p-error">{formik.errors?.product?.varient}</div>
              )}
          </div>

          {/* <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.purchasePrice" className="">
                Purchase Price
              </label>
              <span className=" w-full">
                <InputNumber
                  id="product.purchasePrice"
                  name="product.purchasePrice"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.product?.purchasePrice}
                  onValueChange={formik.handleChange}
                  disabled
                  prefix="RS "
                />
              </span>
            </div>
            {formik.touched?.product?.purchasePrice &&
              formik.errors?.product?.purchasePrice && (
                <div className="p-error">
                  {formik.errors?.product?.purchasePrice}
                </div>
              )}
          </div> */}

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.quantity" className="">
                Quantity
              </label>
              <span className=" w-full">
                <InputNumber
                  id="product.quantity"
                  name="product.quantity"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.product?.quantity}
                  onValueChange={formik.handleChange}
                />
              </span>
            </div>
            {formik.touched?.product?.quantity &&
              formik.errors?.product?.quantity && (
                <div className="p-error">
                  {formik.errors?.product?.quantity}
                </div>
              )}
          </div>

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.salePrice" className="">
                Sale Price
              </label>
              <span className=" w-full">
                <InputNumber
                  disabled={user?.role_id != 1 ? true : false}
                  id="product.salePrice"
                  name="product.salePrice"
                  className="w-full text-lg p-primary-input"
                  value={formik?.values?.product?.salePrice}
                  onValueChange={formik.handleChange}
                  prefix="RS "
                />
              </span>
            </div>
            {formik.touched?.product?.salePrice &&
              formik.errors?.product?.salePrice && (
                <div className="p-error">
                  {formik.errors?.product?.salePrice}
                </div>
              )}
          </div>

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.discount" className="">
                Discount
              </label>
              <span className=" w-full">
                <InputNumber
                  id="product.discount"
                  name="product.discount"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.product?.discount}
                  onValueChange={formik.handleChange}
                  prefix="RS "
                />
              </span>
            </div>
            {formik.touched?.product?.discount &&
              formik.errors?.product?.discount && (
                <div className="p-error">
                  {formik.errors?.product?.discount}
                </div>
              )}
          </div>

          <div className="">
            <div className="flex flex-col gap-2">
              <label htmlFor="product.total" className="">
                Amount
              </label>
              <span className=" w-full">
                <InputNumber
                  id="pproduct.total"
                  name="product.total"
                  className="w-full text-lg p-primary-input"
                  value={formik.values?.product?.total}
                  onValueChange={formik.handleChange}
                  disabled
                  prefix="RS "
                />
              </span>
            </div>
            {formik.touched?.product?.total &&
              formik.errors?.product?.total && (
                <div className="p-error">{formik.errors?.product?.total}</div>
              )}
          </div>
        </div>

        <div className="mt-8">
          <div className="flex justify-end gap-4">
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={handleCancel}
            />
            <Button
              label={editingProduct ? "Edit" : "Add"}
              icon="pi pi-check"
              className="p-secondary-btn"
              type="button"
              onClick={editingProduct ? updateProduct : handleAddVarient}
              disabled={!validateVarient}
            />
          </div>
        </div>
      </div>
    </>
  );
}
