import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Invoice() {
  const { user } = useSelector((state) => state.AuthReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);
  const { allVarients, products } = useSelector(
    (state) => state.ProductReducer
  );

  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    if (specificSale) {
      // Function to update the current time
      const updateTime = () => {
        const date = new Date(specificSale?.created_at);
        const timeString = date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        });
        setCurrentTime(timeString);
      };

      // Update the time initially
      updateTime();

      // Update the time every second
      const interval = setInterval(updateTime, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    } else {
      // Function to update the current time
      const updateTime = () => {
        const date = new Date();
        const timeString = date.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        });
        setCurrentTime(timeString);
      };

      // Update the time initially
      updateTime();

      // Update the time every second
      const interval = setInterval(updateTime, 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [specificSale]);

  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    if (specificSale) {
      // Function to update the current date
      const updateDate = () => {
        const date = new Date(specificSale?.created_at);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setCurrentDate(dateString);
      };

      // Update the date initially
      updateDate();

      // Clean up on component unmount
      return () => {};
    } else {
      // Function to update the current date
      const updateDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const dateString = `${day}/${month}/${year}`;
        setCurrentDate(dateString);
      };

      // Update the date initially
      updateDate();

      // Clean up on component unmount
      return () => {};
    }
  }, [specificSale]);

  const handleProduct = (rowData) => {
    const product = products.find((pro) => pro.id === rowData.product);
    if (product) {
      return product.article_name;
    }
    return ""; // Return an empty string if no matching product is found
  };

  const handleVarient = (rowData) => {
    const varient = allVarients.find(
      (vari) => vari.varient_id === rowData.varient
    );

    if (varient) {
      const parsedOptions = JSON.parse(varient.options);
      const optionLabel = parsedOptions.map((option) => {
        return `${option} - ${varient[option]?.value || "N/A"}`;
      });

      return optionLabel.map((op, i) => <div key={i}>{op}</div>);
    }

    return ""; // Return an empty string if no matching product is found
  };

  const [totalQty, setTotalQty] = useState(0);

  //   useEffect(() => {
  //     if (salesDetails) {
  //       let qty = 0;
  //       salesDetails.map((product) => {
  //         qty = qty + product.quantity;
  //       });
  //       setTotalQty(qty);
  //     }
  //   }, [salesDetails]);

  // useEffect(()=>{
  //   const customer = customers.find((customer) => customer.id === sales.customer_id);
  //   if (Object.keys(customer).length !== 0) {
  //     setCustomer(customer)
  //   } else {
  //     setCustomer({});
  //   }
  //   const staffs = staff.find((staff) => staff.id === sales.staff_id);
  //   if (Object.keys(staffs).length !== 0) {
  //     setSelectedStaff(staffs)
  //   } else {
  //     setSelectedStaff({})
  //   }
  // },[])

  //   useEffect(() => {
  //     const customer = customers.find(
  //       (customer) => customer.id === sales.customer_id
  //     );
  //     if (customer && Object.keys(customer).length !== 0) {
  //       setCustomer(customer);
  //     } else {
  //       setCustomer({});
  //     }

  //     const staffs = staff.find((staff) => staff.id === sales.staff_id);
  //     if (staffs && Object.keys(staffs).length !== 0) {
  //       setSelectedStaff(staffs);
  //     } else {
  //       setSelectedStaff({});
  //     }
  //   }, []);

  return (
    <div>
      <div className="">
        <div className="w-full flex justify-center">
          <img src="/images/logo.png" className="w-24 mt-1" />
        </div>

        <div className="w-full mt-1 leading-tight text-xs">
          <div className="text-center font-bold">
            Street 8 Block D Shahaeenabad, Gujranwala
          </div>
          <div className="text-center font-bold">
            shoemaker@gmail.com | 03156263069
          </div>
        </div>

        <div className="mt-3">
          <div className="text-center text-base font-bold">SALES RECEIPT</div>
        </div>

        <div className="w-full mt-3 leading-tight">
          {/* <div className="text-center text-sm font-bold mb-2">
            {selectedStaff && Object.keys(selectedStaff || {}).length !== 0
              ? selectedStaff.name
              : ""}
            Abdullah Sajjad
          </div> */}
          <div className="text-center text-xs font-bold">
            {specificSale?.customer_name ? specificSale?.customer_name : ""}
          </div>
          <div className="text-center text-xs font-bold">
            {specificSale?.customer_phone ? specificSale?.customer_phone : ""}
          </div>
          {/* <div className="text-center text-xs font-bold">
            {customer ? customer.address : ""}
          </div> */}
        </div>

        <div className="w-full flex justify-center mt-4">
          <div
            className=" font-bold flex justify-between"
            style={{ fontSize: "0.65rem", width: "98%" }}
          >
            {/* <span className="">ID: {sales ? sales.id : ""}</span> */}
            <span>{user.first_name}</span>
            <span>{currentDate}</span>
            <span>{currentTime}</span>
          </div>
        </div>

        <div className="w-full flex justify-center mt-2 receipt">
          <DataTable
            className=" text-xs pb-1"
            value={specificSale?.products}
            style={{ width: "98%" }}
          >
            <Column
              header="Product"
              className="w-4/12 item-name"
              headerClassName="text-sm"
              body={handleProduct}
            />
            <Column
              header="Varient"
              className="w-5/12 item-name"
              headerClassName="text-sm"
              body={handleVarient}
            />

            <Column
              header="Qty"
              className="w-1/12 item-price"
              headerClassName="text-sm"
              body={(rowData) => rowData.quantity}
            />
            <Column
              header="Price"
              className="w-1/12 item-price"
              headerClassName="text-sm"
              body={(rowData) => rowData.salePrice.toLocaleString("en-IN")}
            />
            <Column
              body={(rowData) => rowData.discount.toLocaleString("en-IN")}
              header="Discount"
              className="w-1/12 item-total"
              headerClassName="text-sm"
            />
          </DataTable>
        </div>

        <div
          className="flex justify-center mx-auto"
          style={{ borderBottom: "1px dashed black", width: "98%" }}
        >
          <div className="flex justify-between w-full text-xs font-bold mx-1 my-2">
            <div>
              {specificSale?.products ? specificSale?.products.length : 0} x
              Items Sold
            </div>
            <div className="flex gap-2">
              <div>SubTotal</div>
              <div>
                RS{" "}
                {specificSale?.total_sales
                  ? specificSale?.total_sales.toLocaleString("en-IN")
                  : 0}
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex justify-center mx-auto"
          style={{ borderBottom: "1px dashed black", width: "98%" }}
        >
          <div className="flex justify-between w-full text-sm font-bold mx-1 my-1">
            <div>Discount</div>
            <div>
              RS{" "}
              {specificSale?.total_discount
                ? specificSale?.total_discount.toLocaleString("en-IN")
                : 0}
            </div>
          </div>
        </div>

        <div
          className="flex justify-center mx-auto"
          style={{ borderBottom: "1px dashed black", width: "98%" }}
        >
          <div className="flex justify-between w-full text-sm font-bold mx-1 my-1">
            {/* <div>
              VAT ({specificSale?.vat_per ? specificSale?.vat_per : 0}%)
            </div> */}
            <div>
              RS{" "}
              {specificSale?.vat_amount
                ? specificSale?.vat_amount.toLocaleString("en-IN")
                : 0}
            </div>
          </div>
        </div>

        <div
          className="flex justify-center mx-auto"
          style={{ borderBottom: "1px dashed black", width: "98%" }}
        >
          <div className="flex justify-between w-full text-xl font-bold mx-1 my-2">
            <div>TOTAL</div>
            <div>
              RS{" "}
              {specificSale?.total_amount
                ? specificSale?.total_amount.toLocaleString("en-IN")
                : 0}
            </div>
          </div>
        </div>

        {/* <div className="flex justify-center mx-auto" style={{ width: "98%" }}>
          <div className="flex justify-between w-full text-sm font-bold mx-1 my-1">
            <div>Cash</div>
            <div>
              {salesPayment
                ? salesPayment.paid_amount.toLocaleString("en-IN")
                : ""}
            </div>
          </div>
        </div>

        <div className="flex justify-center mx-auto" style={{ width: "98%" }}>
          <div className="flex justify-between w-full text-sm font-bold mx-1">
            <div>Change</div>
            <div>
              {salesPayment
                ? salesPayment.return_amount.toLocaleString("en-IN")
                : ""}
            </div>
          </div>
        </div> */}

        <div className="text-center font-bold mt-3 leading-tight">
          <div className="text-xl">THANK YOU!</div>
          <div className="text-sm mt-2">Follow us on Social Media</div>
        </div>

        <div className="text-center text-xl mt-2 flex gap-1 justify-center">
          <FaWhatsapp />
          <FaFacebook />
          <FaInstagram />
          <FaPinterest />
          <FaTwitter />
        </div>
      </div>
    </div>
  );
}
