import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function AddVarientForm({
  formik,
  setShowVarientForm,
  setValidateVarient,
  showVarientForm,
  editingVarient,
  setEditingVarient,
}) {
  const { varientOptions, optionValues } = useSelector(
    (state) => state.ProductReducer
  );

  const [showGenerateVarientDialog, setShowGenerateVarientDialog] =
    useState(false);

  const [selectedColors, setSelectedColors] = useState([]);
  const [size12, setSize12] = useState(false);

  // function adjustQuantityBasedOnSize(sizeString) {
  //   let quantity = 1;

  //   if (sizeString.includes("6")) {
  //     quantity = 1;
  //   } else if (sizeString.includes("7") || sizeString.includes("10")) {
  //     quantity = 2;
  //   } else if (sizeString.includes("8") || sizeString.includes("9")) {
  //     quantity = 3;
  //   } else if (sizeString.includes("11") || sizeString.includes("12")) {
  //     quantity = 1;
  //   }

  //   return quantity;
  // }

  // function generateVariants(formikOptions, variantOptions, selectedColors) {
  //   const variants = [];

  //   // Helper function to get variant option values by option ID
  //   function getOptionValues(optionId) {
  //     return variantOptions.filter((option) => option.option_id === optionId);
  //   }

  //   // Recursive function to generate all combinations
  //   function generateCombinations(index, combination) {
  //     if (index === formikOptions.length) {
  //       const variantObject = {};
  //       combination.forEach((option) => {
  //         const { label } = formikOptions.find(
  //           (opt) => opt.id === option.option_id
  //         );
  //         variantObject[label] = {
  //           value: option.value,
  //           id: option.id,
  //           option_id: option.option_id,
  //           created_at: option.created_at,
  //           updated_at: option.updated_at,
  //         };
  //       });

  //       // Add default values for id, price, purchasePrice, and quantity
  //       variantObject.id = variants.length + 1;
  //       variantObject.price = formik.values.price;
  //       variantObject.purchasePrice = formik.values.purchasePrice;
  //       variantObject.quantity = adjustQuantityBasedOnSize(
  //         variantObject?.size?.value
  //       );

  //       variants.push(variantObject);
  //       return;
  //     }

  //     const currentOption = formikOptions[index];
  //     let optionValues = getOptionValues(currentOption.id);

  //     // Filter the option values if the current option is 'color'
  //     if (currentOption.label === "color") {
  //       optionValues = optionValues.filter((option) =>
  //         selectedColors.some((color) => color.id === option.id)
  //       );
  //     }

  //     for (const option of optionValues) {
  //       generateCombinations(index + 1, [...combination, option]);
  //     }
  //   }

  //   generateCombinations(0, []);
  //   return variants;
  // }

  // const handleGenerateVarients = () => {
  //   const varients = generateVariants(
  //     varientOptions,
  //     optionValues,
  //     selectedColors
  //   );

  //   if (varients && varients.length > 0) {
  //     formik.setFieldValue("varients", varients);

  //     setSelectedColors([]);
  //     setShowGenerateVarientDialog(false);
  //   }
  // };

  function adjustQuantityBasedOnSize(sizeString) {
    let quantity = 1;

    if (sizeString.includes("6")) {
      quantity = 1;
    } else if (sizeString.includes("7") || sizeString.includes("10")) {
      quantity = 2;
    } else if (sizeString.includes("8") || sizeString.includes("9")) {
      quantity = 3;
    } else if (sizeString.includes("11") || sizeString.includes("12")) {
      quantity = 1;
    }

    return quantity;
  }

  function generateVariants(
    formikOptions,
    variantOptions,
    selectedColors,
    size12
  ) {
    const variants = [];

    // Helper function to get variant option values by option ID
    function getOptionValues(optionId) {
      return variantOptions.filter((option) => option.option_id === optionId);
    }

    // Recursive function to generate all combinations
    function generateCombinations(index, combination) {
      if (index === formikOptions.length) {
        const variantObject = {};
        combination.forEach((option) => {
          const { label } = formikOptions.find(
            (opt) => opt.id === option.option_id
          );
          variantObject[label] = {
            value: option.value,
            id: option.id,
            option_id: option.option_id,
            created_at: option.created_at,
            updated_at: option.updated_at,
          };
        });

        // Add default values for id, price, purchasePrice, and quantity
        variantObject.id = variants.length + 1;
        variantObject.price = formik.values.price;
        variantObject.purchasePrice = formik.values.purchasePrice;
        variantObject.quantity = adjustQuantityBasedOnSize(
          variantObject?.size?.value
        );

        variants.push(variantObject);
        return;
      }

      const currentOption = formikOptions[index];
      let optionValues = getOptionValues(currentOption.id);

      // Filter the option values if the current option is 'color'
      if (currentOption.label === "color") {
        optionValues = optionValues.filter((option) =>
          selectedColors.some((color) => color.id === option.id)
        );
      }

      // Check if the current option is 'size'
      if (currentOption.label === "size") {
        if (size12) {
          // Filter to only include size 12
          // optionValues = optionValues.filter((option) => option.value === "12");
        } else {
          // Filter to exclude size 12
          optionValues = optionValues.filter((option) => option.value !== "12");
        }
      }

      for (const option of optionValues) {
        generateCombinations(index + 1, [...combination, option]);
      }
    }

    generateCombinations(0, []);
    return variants;
  }

  const handleGenerateVarients = () => {
    const varients = generateVariants(
      varientOptions,
      optionValues,
      selectedColors,
      size12 // Pass the size12 flag here
    );

    if (varients && varients.length > 0) {
      formik.setFieldValue("varients", varients);

      setSelectedColors([]);
      setShowGenerateVarientDialog(false);
      setSize12(false);
    }
  };

  const optionValueFilter = (id) => {
    return optionValues.filter((option) => option.option_id === id);
  };

  const getVarientOptions = varientOptions.reduce((acc, option) => {
    acc[option.label] = "";
    return acc;
  }, {});

  const getEditVarientOptions = editingVarient
    ? varientOptions.reduce((acc, option) => {
        acc[option.label] = editingVarient[option.label];
        return acc;
      }, {})
    : null;

  const handleCancel = () => {
    setShowVarientForm(false);
    setValidateVarient(false);
    setEditingVarient(null);
    formik.setValues({
      ...formik.values,
      varient: {
        ...getVarientOptions,
        quantity: "",
        price: formik?.values?.samePrice ? formik?.values?.price : 0,
        purchasePrice: formik?.values?.samePurchasePrice
          ? formik?.values?.purchasePrice
          : 0,
      }, // Reset the varient form
    });
  };

  const updateVarientInArray = (updatedVarient) => {
    const index = formik.values.varients.findIndex(
      (varient) => varient.id === editingVarient.id
    );

    if (index !== -1) {
      // Update the values of the object at the found index
      formik.values.varients[index] = {
        ...formik.values.varients[index],
        ...updatedVarient,
      };
    }

    formik.setValues({
      ...formik.values,
      varient: {
        ...getVarientOptions,
        quantity: "",
        price: formik?.values?.samePrice ? formik?.values?.price : 0,
        purchasePrice: formik?.values?.samePurchasePrice
          ? formik?.values?.purchasePrice
          : 0,
      }, // Reset the varient form
    });

    setEditingVarient(null); // Reset the editingVarient state after updating
    setShowVarientForm(false); // Close the varient form after updating

    toast.success("Varient Updated Successfully!");
  };

  const updateVarient = () => {
    if (editingVarient) {
      formik.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          if (formik.isValid) {
            updateVarientInArray(formik.values.varient);
          }
        } else {
          console.log("Validation errors:", errors);
        }
      });
    }
  };

  const handleAddVarient = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (formik.isValid) {
          const varientsArray = Array.isArray(formik.values.varients)
            ? formik.values.varients
            : [];

          const newVarient = formik.values.varient
            ? {
                ...formik.values.varient,
                id: formik.values.varients.length + 1,
              }
            : {};

          formik.setValues({
            ...formik.values,
            varients: [...varientsArray, newVarient],
            varient: {
              ...getVarientOptions,
              quantity: "",
              price: formik?.values?.samePrice ? formik?.values?.price : 0,
              purchasePrice: formik?.values?.samePurchasePrice
                ? formik?.values?.purchasePrice
                : 0,
            }, // Reset the varient form
          });

          setShowVarientForm(false);
          setValidateVarient(false);
          toast.success("Varient Added Successfully!");
        }
      } else {
        console.log("Validation errors:", errors);
      }
    });
  };

  useEffect(() => {
    // Set the formik values based on the editingVarient when it changes
    if (editingVarient) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        varient: {
          ...prevValues.varient,
          ...getEditVarientOptions,

          quantity: editingVarient?.quantity,
          price: editingVarient?.price,
          purchasePrice: editingVarient?.purchasePrice,
        },
      }));
    }
  }, [editingVarient]);
  return (
    <>
      <div className="mt-3 flex justify-end gap-3">
        {formik.values.varients.length > 0 ? (
          <Button
            label="Clear All Varients"
            icon="pi pi-times"
            className="p-red-btn"
            type="button"
            onClick={() => {
              formik.setFieldValue("varients", []);
            }}
          />
        ) : null}
        <Button
          label="Generate All Varients"
          icon="pi pi-cog"
          className="p-primary-btn"
          onClick={() => setShowGenerateVarientDialog(true)}
          type="button"
        />
        <Button
          label="Add Varient"
          icon="pi pi-plus"
          className="p-primary-btn"
          onClick={() => {
            setShowVarientForm(true);
            setValidateVarient(true);
          }}
        />
      </div>

      {showVarientForm && (
        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {editingVarient ? "Edit" : "Add"} Varient
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {varientOptions.map((varient) => {
              return (
                <div className="" key={varient.id}>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor={"varient." + varient.label}
                      className="capitalize"
                    >
                      {varient.label}
                    </label>
                    <Dropdown
                      id={"varient." + varient.label}
                      name={"varient." + varient.label}
                      className="!w-full text-lg p-primary-input"
                      value={formik.values?.varient[`${varient?.label}`]}
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          varient: {
                            ...formik.values.varient,
                            [varient.label]: e.value,
                          },
                        });
                      }}
                      options={optionValueFilter(varient.id)}
                      optionLabel="value"
                      optionValue={(e) => {
                        return e;
                      }}
                      filter
                      pt={{
                        root: { className: "w-full" },
                        input: { className: "w-full p-primary-input" },
                        filterIcon: { className: "ml-1" },
                        filterInput: { className: "pl-6" },
                      }}
                    />
                  </div>
                  {formik.touched?.varient?.[varient.label] &&
                    formik.errors?.varient?.[varient.label] && (
                      <div className="p-error">
                        {formik.errors?.varient?.[varient.label]}
                      </div>
                    )}
                </div>
              );
            })}

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="varient.quantity" className="">
                  Quantity
                </label>
                <span className=" w-full">
                  <InputNumber
                    id="varient.quantity"
                    name="varient.quantity"
                    className="w-full text-lg p-primary-input"
                    value={formik.values?.varient?.quantity}
                    onValueChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.varient?.quantity &&
                formik.errors?.varient?.quantity && (
                  <div className="p-error">
                    {formik.errors?.varient?.quantity}
                  </div>
                )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="varient.purchasePrice" className="">
                  Purchase Price
                </label>
                <span className=" w-full">
                  <InputNumber
                    id="varient.purchasePrice"
                    name="varient.purchasePrice"
                    className="w-full text-lg p-primary-input"
                    value={
                      formik?.values?.samePurchasePrice
                        ? formik?.values?.purchasePrice
                        : formik?.values?.varient?.purchasePrice
                    }
                    onValueChange={formik.handleChange}
                    prefix="RS "
                    disabled={formik.values.samePurchasePrice}
                  />
                </span>
              </div>
              {formik.touched?.varient?.purchasePrice &&
                formik.errors?.varient?.purchasePrice && (
                  <div className="p-error">
                    {formik.errors?.varient?.purchasePrice}
                  </div>
                )}
            </div>

            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="varient.price" className="">
                  Sale Price
                </label>
                <span className=" w-full">
                  <InputNumber
                    id="varient.price"
                    name="varient.price"
                    className="w-full text-lg p-primary-input"
                    value={
                      formik?.values?.samePrice
                        ? formik?.values?.price
                        : formik?.values?.varient?.price
                    }
                    onValueChange={formik.handleChange}
                    prefix="RS "
                    disabled={formik.values.samePrice}
                  />
                </span>
              </div>
              {formik.touched?.varient?.price &&
                formik.errors?.varient?.price && (
                  <div className="p-error">{formik.errors?.varient?.price}</div>
                )}
            </div>
          </div>

          <div className="mt-8">
            <div className="flex justify-end gap-4">
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-red-btn"
                type="button"
                onClick={handleCancel}
              />
              <Button
                label={editingVarient ? "Edit" : "Add"}
                icon="pi pi-check"
                className="p-secondary-btn"
                type="button"
                onClick={editingVarient ? updateVarient : handleAddVarient}
              />
            </div>
          </div>
        </div>
      )}

      <Dialog
        header="Generate varients"
        visible={showGenerateVarientDialog}
        onHide={() => setShowGenerateVarientDialog(false)}
        pt={{
          root: {
            className: "w-full sm:w-10/12 md:w-7/12 lg:w-5/12 xl:w-4/12 mx-2",
          },
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-2">
          <div>
            <MultiSelect
              value={selectedColors}
              onChange={(e) => setSelectedColors(e.value)}
              options={optionValues?.filter((op) => op?.option_id === 2)}
              optionLabel="value"
              placeholder="Select Colors"
              maxSelectedLabels={3}
              className="!w-full"
              optionValue={(op) => op}
            />
          </div>

          <div className="my-auto">
            <Checkbox
              inputId="size12"
              name="size12"
              id="size12"
              onChange={() => setSize12(!size12)}
              checked={size12}
              pt={{
                input: ({ context }) => ({
                  className: context.checked
                    ? "bg-primary border-primary hover:border-primary"
                    : "hover:border-primary",
                }),
                icon: { className: "font-bold" },
              }}
            />
            <label htmlFor="size12" className="ml-2">
              Include 12 Size
            </label>
          </div>
        </div>

        {selectedColors?.length > 0 ? null : (
          <div className="p-error">Please select any color</div>
        )}

        <div className="flex justify-end gap-4 mt-8">
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-red-btn"
            type="button"
            onClick={() => {
              setSelectedColors([]);
              setShowGenerateVarientDialog(false);
            }}
          />
          <Button
            label={"Generate"}
            icon="pi pi-check"
            className="p-secondary-btn"
            type="button"
            disabled={selectedColors?.length > 0 ? false : true}
            onClick={handleGenerateVarients}
          />
        </div>
      </Dialog>
    </>
  );
}

// // Function to generate variants
// function generateVariants(formikOptions, variantOptions) {
//   const variants = [];

//   // Helper function to get variant option values by option ID
//   function getOptionValues(optionId) {
//     return variantOptions.filter((option) => option.option_id === optionId);
//   }

//   // Recursive function to generate all combinations
//   function generateCombinations(index, combination) {
//     if (index === formikOptions.length) {
//       const variantObject = {};
//       combination.forEach((option) => {
//         const { label } = formikOptions.find(
//           (opt) => opt.id === option.option_id
//         );
//         variantObject[label] = {
//           value: option.value,
//           id: option.id,
//           option_id: option.option_id,
//           created_at: option.created_at,
//           updated_at: option.updated_at,
//         };
//       });

//       // Add default values for id, price, purchasePrice, and quantity
//       variantObject.id = variants.length + 1;
//       variantObject.price = formik.values.price;
//       variantObject.purchasePrice = formik.values.purchasePrice;
//       variantObject.quantity = adjustQuantityBasedOnSize(
//         variantObject?.size?.value
//       );

//       variants.push(variantObject);
//       return;
//     }

//     const currentOption = formikOptions[index];
//     const optionValues = getOptionValues(currentOption.id);

//     for (const option of optionValues) {
//       generateCombinations(index + 1, [...combination, option]);
//     }
//   }

//   generateCombinations(0, []);
//   return variants;
// }

// const handleGenerateVarients = () => {
//   const varients = generateVariants(varientOptions, optionValues);

//   if (varients && varients.length > 0) {
//     formik.setFieldValue("varients", varients);
//   }
// };
